<template>
  <div class="home" id="home">
    <navigationBar :showBack="showBack">首页</navigationBar>
    <div class="tips">提示：请上传本人“5张”不同角度的照片</div>
    <div class="picture">
      <div class="item">
        <van-uploader :after-read="afterRead1" v-model="fileList1" capture="user" :max-count="1" :before-delete="handelDelete1" />
      </div>
      <div class="item">
        <van-uploader :after-read="afterRead2" v-model="fileList2" capture="user" :max-count="1" :before-delete="handelDelete2" />
      </div>
      <div class="item">
        <van-uploader :after-read="afterRead3" v-model="fileList3" capture="user" :max-count="1" :before-delete="handelDelete3" />
      </div>
      <div class="item">
        <van-uploader :after-read="afterRead4" v-model="fileList4" capture="user" :max-count="1" :before-delete="handelDelete4" />
      </div>
      <div class="item">
        <van-uploader :after-read="afterRead5" v-model="fileList5" capture="user" :max-count="1" :before-delete="handelDelete5" />
      </div>
      
    </div>
    
    <div class="sub" @click="handleSub();">提交</div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import navigationBar from "@/components/navigationBar/navigationBar";
import { getUploader, getSubUploader } from "@/request/api";

export default {
  name: "Home",
  data() {
    return {
      showBack: false,
      token: '',
      fileList1: [],
      fileList2: [],
      fileList3: [],
      fileList4: [],
      fileList5: [],
      url1: '',
      url2: '',
      url3: '',
      url4: '',
      url5: '',
    };
  },
  components: {
    navigationBar
  },
  created() {
    this.token = localStorage.getItem("authorization");
    if(!this.token) {
      Toast('请登录');
      setTimeout(() => {
        this.$router.push("/login");
      }, 1500)
    } else {
      Dialog.alert({
        title: '温馨提示',
        message: '请上传本人“5张”不同角度的照片',
      }).then(() => {
        // on close
      });
    }
  },

  methods: {
    afterRead1(file) {
      this.getUploader(file, '1')
    },
    afterRead2(file) {
      this.getUploader(file, '2')
    },
    afterRead3(file) {
      this.getUploader(file, '3')
    },
    afterRead4(file) {
      this.getUploader(file, '4')
    },
    afterRead5(file) {
      this.getUploader(file, '5')
    },
    handelDelete1() {
      this.url1 = '';
      return true
    },
    handelDelete2() {
      this.url2 = '';
      return true
    },
    handelDelete3() {
      this.url3 = '';
      return true
    },
    handelDelete4() {
      this.url4 = '';
      return true
    },
    handelDelete5() {
      this.url5 = '';
      return true
    },
    getUploader(file, status) {
      file.status = 'uploading';
      file.message = '上传中...';
      const param = {
        base64: file.content,
        suffix: file.file.type.slice(6, file.file.type.length),
        type: 3
      };
      getUploader(param).then((res) => {
        file.status = "done";
        file.message = "上传成功";
        if(status == 1) {
          this.url1 = res;
        }
        if(status == 2) {
          this.url2 = res;
        }
        if(status == 3) {
          this.url3 = res;
        }
        if(status == 4) {
          this.url4 = res;
        }
        if(status == 5) {
          this.url5 = res;
        }
      })
    },
    handleSub() {
      if(this.url1 && this.url2 && this.url3 && this.url4 && this.url5) {
        const param = {
          url1: this.url1,
          url2: this.url2,
          url3: this.url3,
          url4: this.url4,
          url5: this.url5,
        }
        getSubUploader(param).then((res) => {
          if(res == true) {
            Toast.success('上传成功');
          } else {
            Toast.fail('上传失败');
          }
        })
      } else {
        Toast('请全部上传之后再提交');
      }
    }
  },
};
</script>

<style scoped lang="less">
.home {
  min-height: calc(100vh - 0.88rem - 0.98rem);
  margin-top: 1.08rem;
  padding: 0 0 0.98rem 0;
  
  .tips {
    font-size: 0.26rem;
    padding: 0 0 0.2rem 0.24rem;
    color: #F79200;
  }
  
  .picture {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .item {
      width: 33.3%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    /deep/ .van-uploader__upload {
      width: 2rem;
      height: 2rem;
      margin: 0 0 0.1rem 0;
    }

    /deep/ .van-uploader__preview-image {
      width: 2rem;
      height: 2rem;
      margin: 0;
    }

    /deep/ .van-uploader:after{
      content: '';
      width: 2rem;
      height: 2rem;
      border: 1px solid transparent;
    }

    /deep/ .van-uploader__upload-icon {
      font-size: 0.8rem;
    }
  }

  .sub {
      width: 96%;
      height: 0.9rem;
      background: linear-gradient(90deg, #FFBE60, #F79200);
      border-radius: 0.45rem;
      text-align: center;
      line-height: 0.9rem;
      font-size: 0.34rem;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: #ffffff;
      margin: 1rem auto 0;
    }

}
</style>
<style>
.van-dialog__confirm {
    color: #F79200 !important;
  }
</style>